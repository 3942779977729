<template>
  <v-container fluid>
    <PageToolbar
      :title="`${caixa_id}${caixa ? ' - ' + caixa.descricao : ''}`"
      icon="mdi-cash-register"
      dark
      voltar
    />

    <v-card>
      <v-card-title class="d-block">
        <div class="d-flex">
          <div
            v-if="tipo_data == 'range'"
            class="mr-4 d-flex align-center justify-center"
          >
            <DataField
              :disabled="!PCaixaEditar ? true : false"
              hide_details
              :data_sync.sync="data_inicio"
              :label="$tc('global.inicio')"
            />
          </div>
          <div
            v-if="tipo_data == 'range'"
            class="mr-4 d-flex align-center justify-center"
          >
            <DataField
              :disabled="!PCaixaEditar ? true : false"
              hide_details
              :data_sync.sync="data_fim"
              :label="$tc('global.fim')"
              :data_min="data_inicio"
            />
            <v-btn
              :disabled="!PCaixaEditar ? true : false"
              @click="getRegistros"
              text
              class="mx-2"
            >
              <v-icon>mdi-magnify</v-icon>
              Buscar
            </v-btn>
          </div>
          <v-card
            v-if="tipo_data != 'range'"
            outlined
            class="d-flex align-center"
            height="40px"
            style="overflow: hidden"
          >
            <v-btn
              :disabled="!PCaixaEditar ? true : false"
              @click="add_sub_date = '-1'"
              tile
              depressed
              height="40px"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-divider vertical class="mx-0"></v-divider>
            <!-- Data Inicio e campo padrao -->
            <v-menu
              ref="menu"
              :disabled="!PCaixaEditar ? true : false"
              v-model="menu_picker"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  style="max-width: 130px"
                  :value="dateFormated"
                  class="text-body-2"
                  append-icon="mdi-calendar"
                  @click:append="menu_picker = true"
                  solo
                  flat
                  dense
                  readonly
                  hide-details
                  :disabled="!PCaixaEditar ? true : false"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="changeDate"
                :disabled="!PCaixaEditar ? true : false"
                @change="dateChange"
                :type="tipo_data === 'dia' ? 'date' : 'month'"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
            <v-divider class="mx-0" vertical></v-divider>
            <v-btn
              :disabled="!PCaixaEditar ? true : false"
              @click="add_sub_date = '1'"
              tile
              depressed
              height="40px"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
          <v-btn-toggle v-model="tipo_data" class="ml-3">
            <v-btn
              :disabled="!PCaixaEditar ? true : false"
              value="dia"
              class="text-caption"
              height="40px"
            >
              {{ $tc("global.dia") }}
            </v-btn>
            <v-btn
              :disabled="!PCaixaEditar ? true : false"
              value="mes"
              class="text-caption"
              height="40px"
            >
              {{ $tc("global.mes") }}
            </v-btn>
          </v-btn-toggle>
          <v-btn
            outlined
            class="text-caption ml-3"
            height="40px"
            @click="listToday"
            :disabled="!PCaixaEditar ? true : false"
          >
            {{ $tc("global.hoje") }}
          </v-btn>
          <v-btn
            :disabled="!PCaixaEditar ? true : false"
            outlined
            class="text-caption ml-3"
            height="40px"
            @click="tipo_data = 'range'"
          >
            {{ $tc("global.livre") }}
          </v-btn>
          <v-spacer></v-spacer>
          <!--BOTAO DO DIALOG DE BAIXA EM LOTES -->
          <v-btn
            v-if="caixa.tipo_caixa == 'GERAL'"
            color="tocs_gray_2"
            class="ml-4 white--text"
            @click="goToBaixaEmLote"
          >
            <v-icon class="pr-2" color="lightblue">
              mdi-download-multiple
            </v-icon>
            {{ $tc("global.lancamento") }} em lote
          </v-btn>
          <DialogBaixaEmLotesAf
            v-if="dialogBaixaEmLotesAf"
            :dialogBaixaEmLotesAf.sync="dialogBaixaEmLotesAf"
            @mostrar-baixa-em-lote-finalizada="mostrarBaixaEmLoteFinalizada"
            @fetch-movimentacoes="getRegistros"
            :caixa_id="caixa_id"
            tipo_caixa="MOV"
          />
          <v-btn
            @click="dialogTransferencia = !dialogTransferencia"
            class="ml-4 white--text"
            color="black"
          >
            <v-icon class="pr-2"> mdi-swap-horizontal </v-icon>
            {{ $tc("global.transferencia") }}
          </v-btn>

          <v-btn
            class="ml-4 white--text"
            @click="dialogTotaisCaixa = !dialogTotaisCaixa"
            color="button_2"
          >
            <v-icon class="pr-2">mdi-cash-multiple</v-icon>
            {{ $tc("global.saldo") }}
          </v-btn>
          <v-btn
            :disabled="!PCaixaEditar ? true : false"
            class="ml-4 white--text"
            @click="addCaixaMov"
            color="button_1"
          >
            <v-icon class="pr-2">mdi-plus</v-icon>
            {{ $tc("global.add") }}
          </v-btn>
        </div>
        <!-- <v-row>
          <v-col cols="12" md="2">
            <v-combobox
              :disabled="!PCaixaEditar ? true : false"
              v-model="selectedMoeda"
              :items="moedas"
              :label="'Tipo' + ' ' + $tc('global.moeda')"
              item-text="sigla"
              item-value="id"
              clearable
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="2">
            <v-combobox
              :disabled="!PCaixaEditar ? true : false"
              v-model="selectedFormaPagamento"
              :items="formas_pagamentos"
              :label="$tc('global.formapagamento')"
              item-text="descricao"
              item-value="id"
              clearable
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="2">
            <v-combobox
              :disabled="!PCaixaEditar ? true : false"
              v-model="selectedUsuario"
              :items="usuarios"
              :label="$tc('global.usuario')"
              item-text="name"
              item-value="id"
              clearable
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :disabled="!PCaixaEditar ? true : false"
              return-object
              v-model="selectedTipo"
              :items="tipos"
              :label="$tc('global.tipo')"
              clearable
            ></v-select>
          </v-col>
        </v-row> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :loading="loadingRegistros"
          :headers="headers"
          :items="registros"
          :items-per-page="15"
          multi-sort
          class="data-tables data-tables__row-click"
          @click:row="goToCaixaMov"
        >
          <template v-slot:item.circle="{ item }">
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo === 'E'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="success"> mdi-plus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.entrada") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo === 'S'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="error"> mdi-minus-circle </v-icon>
              </template>
              <span>
                {{ $tc("global.saida") }}
              </span>
            </v-tooltip>
            <v-tooltip bottom color="darkblue">
              <template v-if="item.tipo === 'N'" v-slot:activator="{ on }">
                <v-icon v-on="on" color="tocs_gray_2">
                  mdi-alpha-n-circle
                </v-icon>
              </template>
              <span> Essa entrada não acrescenta ao total do caixa </span>
            </v-tooltip>
          </template>
          <template v-slot:item.data="{ item }">
            {{ item.data | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.valor="{ item }">
            <span v-if="item.sigla === 'G$'">{{ item.valor | guarani }}</span>
            <span v-else>{{ item.valor | currency }}</span>
          </template>
          <!-- <template v-slot:item.action="{ item }">
            <v-btn
              v-if="item.data === data_hoje"
              icon
              @click.stop="cancelarRegistroMovimentacao(item)"
            >
              <v-icon color="red">mdi-close-circle-outline</v-icon>
            </v-btn>
          </template> -->
          <template v-slot:item.recibo_id="{ item }">
            <div v-if="item.recibo_id">
              <v-btn
                v-if="item.historico != 'TRANSFERÊNCIA'"
                icon
                @click.stop="openReciboPdf(item.recibo_id)"
              >
                <v-icon>mdi-alpha-r-box</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                @click.stop="openReciboPdfTransferencia(item.recibo_id)"
              >
                <v-icon>mdi-alpha-t-box</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DialogTotaisCaixa
      v-if="dialogTotaisCaixa"
      :dialogTotaisCaixa.sync="dialogTotaisCaixa"
    />
    <DialogCaixasMov
      v-if="dialogCaixasMov"
      :dialogCaixasMov.sync="dialogCaixasMov"
      :caixa="caixa"
      :edit="edit"
      :item="caixa_mov"
      :formas_pagamentos="formas_pagamentos"
      @fetch-caixa-mov="getRegistros"
    />
    <DialogCancelamento
      v-if="dialogCancelarMovimentacao"
      :dialogCancelarMovimentacao.sync="dialogCancelarMovimentacao"
      :item="dadosParaCancelar"
      @fetch-caixa-mov="getRegistros"
    />

    <DialogGerarRecibo
      v-if="dialogGerarRecibo"
      :dialogGerarRecibo.sync="dialogGerarRecibo"
      :item="item"
      origem="CAIXA-MOV"
      @fetch-caixa-mov="getRegistros"
    />

    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="RECIBO"
    />

    <DialogTransferencia
      v-if="dialogTransferencia"
      :dialogTransferencia.sync="dialogTransferencia"
      :caixaPdv="false"
      @fetch-caixas-items="getRegistros"
    />

    <DialogBaixaEmLoteFinalizada
      v-if="dialogBaixaEmLoteFinalizada"
      :show.sync="dialogBaixaEmLoteFinalizada"
      :lancamentos="lancamentos_finalizados_baixa_em_lote"
      :caixa_id="caixa_id"
    />
  </v-container>
</template>

<script>
const generateRecibo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboPdf.js"
  );
const generateTransferenciaCaixa = () =>
  import(
    /* webpackChunkName: "transferenciaReciboPdf" */ "../../caixas-pdv/reports/pdvTransferenciaPdf"
  );
import { fetchRecibo } from "@/api/recibos/recibos.js";
import {
  fetchCaixasMov,
  fetchCaixaMovTotais,
} from "@/api/caixas/caixas_mov.js";
import { fetchCaixa } from "@/api/caixas/caixas.js";
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import {
  format,
  parseISO,
  subMonths,
  addMonths,
  subDays,
  addDays,
} from "date-fns";
import { mapState } from "vuex";
import { getUsuariosEmpresaAtivo } from "@/api/usuarios/usuarios.js";
import { mapGetters } from "vuex";

export default {
  name: "CaixaMov",

  data() {
    return {
      loading: true,
      loadingRegistros: true,
      caixa: null,
      registros: [],
      caixa_mov: {},
      caixa_mov_original: {},
      item: {},
      menu_picker: false,
      changeDate: null,
      data_inicio: null,
      data_fim: null,
      add_sub_date: null,
      dialogCaixasMov: false,
      tipo_data: "dia",
      // totalCaixa: null,
      selectedMoeda: null,
      formas_pagamentos: [],
      selectedFormaPagamento: null,
      usuarios: [],
      selectedUsuario: null,
      dialogTransferencia: false,
      tipos: [
        {
          text: "Entrada",
          value: "E",
        },
        {
          text: "Saída",
          value: "S",
        },
      ],
      selectedTipo: null,
      dialogCancelarMovimentacao: false,
      dadosParaCancelar: null,
      pdfDialog: false,
      pdfData: null,
      dialogGerarRecibo: false,
      recibo: null,
      dialogVendasCaixa: false,
      dialogTotaisCaixa: false,
      data_hoje: format(new Date(), "yyyy-MM-dd"),
      dialogBaixaEmLotesAf: false,
      dialogBaixaEmLoteFinalizada: false,
      lancamentos_finalizados_baixa_em_lote: [],
    };
  },

  components: {
    DialogCaixasMov: () => import("./components/DialogCaixasMov.vue"),
    DialogCancelamento: () => import("./components/DialogCancelamento.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    DialogGerarRecibo: () => import("./components/DialogGerarRecibo.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogTotaisCaixa: () => import("./components/DialogTotaisCaixa.vue"),
    DialogTransferencia: () =>
      import("../../caixas-pdv/components/DialogTransferencia.vue"),
    DialogBaixaEmLotesAf: () =>
      import(
        "@/views/dashboard/caixas-pdv/components/baixa-em-lotes/DialogBaixaEmLotesAf.vue"
      ),
    DialogBaixaEmLoteFinalizada: () =>
      import(
        "@/views/dashboard/caixas-pdv/components/baixa-em-lotes/DialogBaixaEmLoteFinalizada.vue"
      ),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    PCaixaEditar() {
      return this.getAccess("Caixas", "editar");
    },
    caixa_id() {
      return this.$route.params.caixa_id;
    },
    headers() {
      let heads = [
        {
          text: "",
          value: "circle",
        },
        {
          text: "ID",
          width: "80px",
          value: "id",
        },
        {
          text: this.$tc("global.data"),
          width: "80px",
          value: "data",
        },
        {
          text: this.$tc("global.usuario"),
          width: "125px",
          value: "user_name",
        },
        {
          text: this.$tc("global.documento"),
          width: "125px",
          value: "documento",
        },
        {
          text: this.$tc("global.historico"),
          width: "125px",
          value: "historico",
        },
        {
          text: this.$tc("global.formapagamento"),
          width: "185px",
          value: "forma_pagamento_descricao",
        },
        {
          text: this.$tc("global.planocontas"),
          width: "155px",
          value: "plano_conta_descricao",
        },

        {
          text: this.$tc("global.recibo"),
          sortable: false,
          align: "center",
          value: "recibo_id",
        },
        // {
        //   text: "RET. IVA",
        //   align: "right",
        //   width: "90px",
        //   sortable: false,
        //   value: "valor_referencia",
        // },

        {
          text: this.$tc("global.moeda"),
          sortable: false,
          value: "sigla",
        },
      ];

      // let umaMoeda = [
      //   {
      //     text: this.$tc("financeiro.entrada"),
      //     align: "right",
      //     width: "120px",
      //     value: "entrada",
      //   },
      //   {
      //     text: this.$tc("financeiro.saida"),
      //     align: "right",
      //     width: "120px",
      //     value: "saida",
      //   },
      //   {
      //     text: this.$tc("financeiro.saldo"),
      //     align: "right",
      //     width: "120px",
      //     value: "saldo",
      //   },
      // ];

      let multiMoedas = [
        {
          text: this.$tc("global.valor"),
          width: "120px",
          value: "valor",
        },
        // {
        //   text: this.$tc("global.cancelar"),
        //   sortable: false,
        //   align: "center",
        //   value: "action",
        // },
      ];

      // if (this.moedaTipoCaixa) {
      //   heads = [...heads, ...umaMoeda];
      // } else {
      //   if (this.moeda) {
      //     heads = [...heads, ...umaMoeda];
      //   } else {
      heads = [...heads, ...multiMoedas];
      //   }
      // }

      return heads;
    },
    url() {
      let queryString = `?caixa=${this.caixa_id}`;
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      // if (this.moedaTipoCaixa) {
      //   queryString += `&moeda=${this.moedaTipoCaixa}&tipo_valor=E`;
      // } else if (this.moeda) {
      //   queryString += `&moeda=${this.moeda}`;
      // }
      if (this.tipo_data != "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.add_sub_date) {
          queryString += `&add_sub_date=${this.add_sub_date}`;
        }
        if (this.changeDate) {
          queryString += `&change_date=${this.changeDate}`;
        }
      }
      if (this.tipo_data == "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.add_sub_date) {
          queryString += `&add_sub_date=${this.add_sub_date}`;
        }
        if (this.data_inicio) {
          queryString += `&data_inicio=${this.data_inicio}`;
        }
        if (this.data_fim) {
          queryString += `&data_fim=${this.data_fim}`;
        }
      }
      if (this.selectedMoeda) {
        queryString += `&moeda_id=${this.selectedMoeda.id}`;
      }
      if (this.selectedFormaPagamento) {
        queryString += `&forma_pagamento_id=${this.selectedFormaPagamento.id}`;
      }
      if (this.selectedUsuario) {
        queryString += `&usuario_id=${this.selectedUsuario.id}`;
      }
      if (this.selectedTipo) {
        queryString += `&tipo=${this.selectedTipo.value}`;
      }
      // if (this.tipo) {
      //   queryString += `&tipo=${this.tipo}`;
      // }
      // if (this.tipo_valor) {
      //   queryString += `&tipo_valor=${this.tipo_valor}`;
      // }
      // if (this.search) {
      //   queryString += `&search=${this.search}`;
      // }

      return queryString;
    },
    now() {
      let result = "";

      if (this.tipo_data === "dia") {
        result = format(new Date(), "yyyy-MM-dd");
      }
      if (this.tipo_data === "mes") {
        result = format(new Date(), "yyyy-MM");
      }
      if (this.tipo_data === "range") {
        result = format(new Date(), "yyyy-MM-dd");
      }

      return result;
    },

    dateFormated() {
      let result = "";

      if (!this.changeDate) {
        return "";
      }

      if (this.tipo_data === "dia") {
        result = format(parseISO(this.changeDate), "dd/MM/yyyy");
      }
      if (this.tipo_data === "mes") {
        result = format(parseISO(this.changeDate), "MM/yyyy");
      }

      return result;
    },

    TotalCaixaPositivos() {
      let total = 0;
      if (this.registros.length) {
        const reducer = (accumulator, currentValue) => {
          if (currentValue.tipo == "E")
            return accumulator + Number(currentValue.valor);
          else return accumulator - Number(currentValue.valor);
        };
        total = this.registros.reduce(reducer, 0);
      }
      return total;
    },

    // TotalCaixaNegativos() {
    //   let total = 0;
    //   if (this.registros.length) {
    //     const reducer = (accumulator, currentValue) => {
    //       if (currentValue.tipo == "Saída")
    //         return accumulator + Number(currentValue.valor);
    //       else return accumulator;
    //     };
    //     total = this.registros.reduce(reducer, 0);
    //   }
    //   return total;
    // },

    TotalCaixas() {
      let totalCaixa = [];
      totalCaixa.total = this.TotalCaixaPositivos;
      if (totalCaixa.total < 0) {
        totalCaixa.cor = "red--text";
      } else {
        totalCaixa.cor = "green--text";
      }
      return totalCaixa;
    },

    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
  },

  watch: {
    data_inicio() {
      if (this.data_inicio > this.data_fim) {
        this.data_fim = this.data_inicio;
      }
    },
    add_sub_date() {
      if (this.add_sub_date) {
        if (this.add_sub_date === "-1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              subDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              subMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
        if (this.add_sub_date === "1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              addDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              addMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
        this.getRegistros();
      }
    },

    url() {
      this.getRegistros();
    },
  },

  methods: {
    // FUNCAO DE ABRIR O RECIBO DE TRANFERENCIA EM PDF
    async openReciboPdfTransferencia(recibo) {
      await generateTransferenciaCaixa().then((module) => {
        let generate = module.generateTransferencia;
        generate(recibo)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    cancelarRegistroMovimentacao(item) {
      if (item.data === format(new Date(), "yyyy-MM-dd")) {
        this.dadosParaCancelar = { ...item };
        this.dialogCancelarMovimentacao = true;
      } else {
        this.$toast.error("Não permitido o cancelamento!");
      }
    },
    addCaixaMov() {
      this.dialogCaixasMov = true;
      this.edit = false;
    },
    goToCaixaMov(item) {
      this.caixa_mov = { ...item };
      this.dialogCaixasMov = true;
      this.edit = true;
    },

    dateChange() {
      if (this.changeDate != this.now) {
        this.getRegistros();
      }
    },

    listToday() {
      this.changeDate = format(new Date(), "yyyy-MM-dd");
      this.tipo_data = "dia";
    },

    async getCaixa() {
      const response = await fetchCaixa(this.caixa_id);
      this.caixa = response;
    },

    async getRegistros() {
      this.loadingRegistros = true;
      this.registros = [];
      const response = await fetchCaixasMov(this.url);
      this.registros = response;
      this.add_sub_date = null;
      this.loadingRegistros = false;
    },

    async openRecibo(caixa_mov_recibo) {
      this.loading = true;

      this.dialogGerarRecibo = true;
      this.item = caixa_mov_recibo;
    },

    async openReciboPdf(recibo_mov) {
      await generateRecibo().then((module) => {
        let generate = module.generateRecibo;
        generate(recibo_mov)
          .then((response) => {
            this.pdfData = response;
            this.pdfDialog = true;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },

    getFormasPagamentos() {
      this.loading = true;
      fetchFormasPagamentos()
        .then((response) => {
          this.formas_pagamentos = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    fetchUsuarios() {
      this.loading = true;
      getUsuariosEmpresaAtivo()
        .then((response) => {
          this.usuarios = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getCaixaMovTotais() {
      this.loading = true;
      fetchCaixaMovTotais(this.caixa_id)
        .then((response) => {
          this.totais_caixa_mov = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goToBaixaEmLote() {
      this.dialogBaixaEmLotesAf = true;
    },
    mostrarBaixaEmLoteFinalizada(lancamentos) {
      this.lancamentos_finalizados_baixa_em_lote = lancamentos;
      this.dialogBaixaEmLoteFinalizada = true;
    },
  },

  async mounted() {
    this.loading = true;
    this.changeDate = this.now;
    this.data_inicio = this.now;
    this.data_fim = this.now;
    await this.getFormasPagamentos();
    await this.getCaixa();
    await this.fetchUsuarios();
    this.getCaixaMovTotais();
    this.loading = false;
  },
};
</script>

<style></style>
